import { Injectable } from '@angular/core';
import { HttpClient , HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
@Injectable({
  providedIn: 'root'
})
export class ApiService {



  // baseURL: string = 'https://admin.iiakerala.apps.eximuz.com';

  baseURL: string = 'http://iiakerala.org/admin/'
  id: any;
  constructor(private httpClient: HttpClient) { }

  public getAnnouncement(){
    return this.httpClient.get(`${this.baseURL}/api/v1/announcement/list`);
  }

  public getCenter(){
    return this.httpClient.get(`${this.baseURL}/api/v1/center/list`); 
  }

  public getInnerCenter(id : any){
    return this.httpClient.get(`${this.baseURL}/api/v1/center/lists?centerid=` + id); 
  }


  public getSubCenter(){
    return this.httpClient.get(`${this.baseURL}/api/v1/subcenter/list`);
  }

  public getTeam(){
    return this.httpClient.get(`${this.baseURL}/api/v1/team/list`);
  }
 
  public getChapter(){
    return this.httpClient.get(`${this.baseURL}/api/v1/chapters/list`);
  }
  

  public getVertical(){
    return this.httpClient.get(`${this.baseURL}/api/v1/vertical/list`);
  }
     
  public getAlbum(){
    return this.httpClient.get(`${this.baseURL}/api/v1/album/list`);
  }

 
  public getCenterTeam(id : any){
    return this.httpClient.get(`${this.baseURL}/api/v1/centerteam/list?centerid=` + id);
  }

  public getGallery(id : any ){
    return this.httpClient.get(`${this.baseURL}/api/v1/album?albumid=` + id);
  }

  public getKeycenter(id : any){
    return this.httpClient.get(`${this.baseURL}/api/v1/keycenter?centerid=` + id);
  }

  public getMembersAbout(){
    return this.httpClient.get(`${this.baseURL}/api/v1/subexecenter`);
  }
  public addSubscription( Name: string, Email: string, Phone: string, Number: string ): Observable<any> {
    const params = new HttpParams()
        .set('name', Name)
        .set('email', Email)
        .set('phone', Phone)
        .set('number', Number);
        return this.httpClient.post<any>(`${this.baseURL}/api/v1/subscription`,  {params});
      }
}

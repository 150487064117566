<app-header></app-header>
<article class="article">
  <section class="page_header">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li class="active">Announcements</li>
      </ul>
      <h1 class="page_title">Announcements </h1>
    </div>
  </section>
  <section class="announcement">
    <div class="container">
      <div class="announce_col" *ngFor="let data of this.announcements">
        <div class="row" *ngIf="data.webvisibility === 'on' ">
          <div class="col-lg-4">
            <i class="announce_img"><img src={{data.image_url}} alt="announce title"></i>
          </div>
          <div class="col-lg-8">
            <!-- <span class="announce_date">{{data.startdate | date:'dd MMM yyyy' }}</span> -->
            <h3 class="announce_title">{{data.title}}</h3>
            <p class="section_text">{{data.description}}</p>
            <span class="announce_end">Date - 23 March 2021</span>
            <span class="announce_venue">Venue - Trivandrum</span>
            <div class="announce_link">
              <a class="link"  href={{data.url_link}} target="_blank" ><svg-icon src="assets/img/link.svg"></svg-icon>{{data.url_link}}</a>
              <!-- <a 
              class="link"
              
              href="javascript:void(0)" 
              style="cursor:pointer"
              (click)="downloadPdf()"
              ><svg-icon src="assets/img/download.svg"></svg-icon> Download PDF </a> -->
           
              <a style="cursor:pointer" href={{data.pdf_url}} download={{data.pdf_url}}><svg-icon src="assets/img/download.svg"></svg-icon>Download File</a>
           
           
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</article>
<app-footer></app-footer>
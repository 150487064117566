<app-header></app-header>

<article class="article">
    <section class="banner">
        <div class="container">
            <owl-carousel-o [options]="homeBanner">
                <ng-template carouselSlide>
                    <div class="banner_item">
                        <i class="banner_image"><img src="assets/img/banner-one.jpg" alt="Kozhikode"></i>
                        <div class="banner_content">
                            <h2 class="banner_title">Government Higher Secondary School Karaparamba</h2>
                            <h6 class="banner_text">Kozhikode, Kerala</h6>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="banner_item">
                        <i class="banner_image"><img src="assets/img/banner-two.jpg" alt="Kozhikode"></i>
                        <div class="banner_content">
                            <h2 class="banner_title">Medical College Kottayam</h2>
                            <h6 class="banner_text">Kottayam, Kerala</h6>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="banner_item">
                        <i class="banner_image"><img src="assets/img/banner-three.jpg" alt="Kozhikode"></i>
                        <div class="banner_content">
                            <h2 class="banner_title">The Samundra Community Hall</h2>
                            <h6 class="banner_text">Kozhikode, Kerala</h6>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </section>

    <section class="home_about">
        <div class="container text_center">
            <p class="section_text lg">The Indian Institute of Architects (IIA) is the National body of Architects in the country. Established in 1917, the institute today has more than 20,000 members and plays a major role in promoting the profession of architecture by organising and uniting the Architects of India to promote aesthetic, scientific and practical efficiency of the profession both in Practice and in Education. IIA is represented on various national and international committees connected with architecture, art and the building industry and is also actively associated with International Union of Architects (UIA) Commonwealth Association of Architect (CAA) and South Asian Association for Regional Co-operation of Architects (SAARCH).</p>
            <a class="section_btn" routerLink="/about">
                <svg-icon src="assets/img/arrow-right.svg" class="icon"></svg-icon>
            </a>
        </div>
    </section>

    <section class="home_center" id="center">
        <div class="container">
            <h3 class="section_title">IIA Centers</h3>
            <div class="center_wrap">
                <div class="center_col" *ngFor="let data of this.centerDatas">
                    <i class="center_img"><img src={{data.image_url}} alt="Trivandrum Center"></i>
                    <a class="center_title" href="#">{{data.title}}</a>
                    <p class="center_text">{{data.description}}</p>
                    <div class="center_social">
                        <a class="social_link" href="mailto:{{data.email_id}}">
                            <svg-icon src="assets/img/mail.svg" class="icon"></svg-icon>{{data.email_id}}
                        </a>
                        <a class="social_link" href="tel:{{data.contact}}">
                            <svg-icon src="assets/img/phone.svg" class="icon"></svg-icon>{{data.contact}}
                        </a>
                    </div>
                    <div class="section_btn sm" [routerLink]="['/center/',data._id]">
                        <svg-icon src="assets/img/arrow-right.svg" class="icon"></svg-icon>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="home_announce">
        <div class="container">
            <h3 class="section_title">Announcement</h3>
            <div class="row announce_row" *ngFor="let announcement of this.announcementDatas">
                <div class="col-lg-3">
                    <h4 class="announce_title">{{announcement.title}}</h4>
                </div>
                <div class="col-lg-6">
                    <p class="announce_text">{{announcement.description}}</p>
                </div>
                <div class="col-lg-2">
                    <span class="announce_date">
                        {{ announcement.startdate | date:'dd MMM yyyy' }}
                    </span>
                </div>
                <div class="col-lg-1 text_right">
                    <a class="section_btn xs" routerLink="/announcement"><svg-icon src="assets/img/arrow-right.svg" class="icon"></svg-icon></a>
                </div>
            </div>
            <div class="text_center">
                <a class="section_btn" routerLink="/announcement"><svg-icon src="assets/img/arrow-right.svg" class="icon"></svg-icon></a>
            </div>
        </div>
    </section>

    <section class="home_media">
        <div class="container">
            <h3 class="section_title">Gallery</h3>
            <owl-carousel-o [options]="homeMedia">
                <ng-template carouselSlide *ngFor="let data of this.albums">
                    <div class="media_item" >
                        <span [routerLink]="['/album/',data._id]">
                            <a class="media_image"><img src={{data.image_url}} alt="Kozhikode"></a>
                            <a  class="media_title">{{data.title}}</a>
                            <a class="section_btn xs" ><svg-icon src="assets/img/arrow-right.svg" class="icon"></svg-icon></a>
                        </span>
                    </div>
                </ng-template>
            </owl-carousel-o>
            <div class="text_center">
                <a class="section_btn" routerLink="/gallery"><svg-icon src="assets/img/arrow-right.svg" class="icon"></svg-icon></a>
            </div>
        </div>
    </section>
</article>

<app-footer></app-footer>